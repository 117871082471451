import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./assets/tailwind.css";
import Carousel3d from "vue-carousel-3d";
import VueAnalytics from "vue-analytics";
import VueMeta from "vue-meta";

Vue.use(Carousel3d);

Vue.use(VueAnalytics, {
  id: "UA-77923382-7",
  router
});

Vue.use(VueMeta, {});

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
