<template>
  <div>
    <header
      class="full-section-container header"
      @touchmove.prevent
      :class="{ active }"
    >
      <div class="section-container">
        <div class="header-container">
          <div class="logo-wrapper">
            <router-link to="/" class="logo">Rewind 2019</router-link>
          </div>
          <div class="menu-wrapper" :class="{ active }">
            <ul class="menu-items">
              <li
                v-for="(item, index) in menu"
                :key="index"
                :class="{ active: currentPosition == item.key }"
              >
                <template v-if="isHomepage">
                  <router-link
                    :to="'/' + item.path"
                    event
                    @click.native="
                      scrollToElement(item, $event);
                      resetMenu();
                    "
                    >{{ item.name }}</router-link
                  >
                </template>
                <template v-else>
                  <router-link
                    :to="'/' + item.path"
                    @click.native="scrollToElementAuto()"
                    >{{ item.name }}</router-link
                  >
                </template>
              </li>
            </ul>
          </div>
          <div class="fifty-wrapper"></div>
          <button
            class="menu-button"
            @click="activateMenu()"
            :class="{ active }"
          >
            <i></i>
          </button>
        </div>
      </div>
    </header>
  </div>
</template>

<script>
export default {
  data() {
    return {
      active: null,
      url: this.$route.path,
      currentPosition: null,
      menu: [
        {
          name: "Nos Top 10 vidéos",
          path: "#videos",
          key: "videos"
        },
        {
          name: "Inspirations",
          path: "#inspirations",
          key: "inspirations"
        },
        {
          name: "Rétro-photos",
          path: "#retro-photos",
          key: "retro-photos"
        },
        {
          name: "Parole aux rédac’chefs",
          path: "#parole-aux-redac-chefs",
          key: "parole-aux-redac-chefs"
        },
        {
          name: "Le fil d’actu de POV",
          path: "#pov",
          key: "pov"
        },
        {
          name: "Au mur des citations",
          path: "#citations",
          key: "citations"
        }
      ]
    };
  },
  methods: {
    scrollToElement(element, event) {
      let name = this.$route.name;

      let id = element.path.substring(1, element.path.length);

      console.log(id);

      if (name === "home") {
        console.log("scrolling");
        // this.$scrollTo("#" + id, 200);
        this.scrollToEl(document.getElementById(id));
      }
      return true;
    },
    activateMenu() {
      if (this.active === null) {
        this.active = "active";
      } else {
        this.active = null;
      }
    },
    resetMenu() {
      this.active = null;
    },
    scrollToElementAuto() {
      let route = this.$route.hash;
      setTimeout(() => {
        this.$scrollTo(route);
      }, 1000);
    },
    scrollToEl(element) {
      window.scroll({
        behavior: "smooth",
        left: 0,
        top: element.offsetTop
      });
    }
  },
  mounted() {
    // this.$store.subscribe((mutation, state) => {
    //   // console.log('STATE: ' + state.menuPosition)
    //   this.currentPosition = state.menuPosition;
    // });
  },
  computed: {
    isHomepage() {
      let name = this.$route.name;
      if (name === "home") {
        return true;
      }
    }
  },
  watch: {
    $route(val) {
      if (val.name !== "home") {
        this.currentPosition = null;
      }
    }
  }
};
</script>

<style scoped lang="scss">
.st0_1 {
  fill: #231f20;
}

.st1_1 {
  fill: #bb2126;
}

.st2_1 {
  fill: #17479d;
}

.st3_1 {
  fill: #ebe719;
}

.st4_1 {
  fill: #22b27c;
}

@-webkit-keyframes AnimationName {
  0% {
    background-position: 0% 51%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 51%;
  }
}
@-moz-keyframes AnimationName {
  0% {
    background-position: 0% 51%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 51%;
  }
}
@keyframes AnimationName {
  0% {
    background-position: 0% 51%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 51%;
  }
}

.header {
  //variables
  --color-white: #fff;
  --color-grey: #777;
  --color-header: #000;

  background: var(--color-header);
  // background: linear-gradient(90deg, red, #004a8f, yellow, green);
  background: linear-gradient(0deg, #e6e6e6, #fff);
  background: #fff;
  box-shadow: 0 0 7px rgba(0, 0, 0, 0.5);
  // background-size: 400% 400%;

  -webkit-animation: AnimationName 30s ease infinite;
  -moz-animation: AnimationName 30s ease infinite;
  animation: AnimationName 30s ease infinite;
  color: var(--color-white);
  height: 65px;
  position: fixed;
  z-index: 100;
  width: 100%;

  display: grid;
  grid-template-columns:
    [full-start] minmax(5vw, 1fr) [container-start] minmax(300px, 1300px)
    [container-end] minmax(5vw, 1fr) [full-end];
  // box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
  top: 0;

  .section-container {
    grid-column: container;
  }
}

.header-container {
  display: grid;
  grid-template-areas: "text nav logo";
  grid-template-columns: 1fr 4fr 1fr;
  grid-auto-rows: auto;

  .logo-wrapper {
    grid-area: text;
    .logo {
      font-size: 24px;
      font-weight: 800;
      line-height: 65px;
      letter-spacing: -0.5px;
      text-decoration: none;
      color: black;
      text-transform: uppercase;
    }
  }

  .fifty-wrapper {
    grid-area: logo;
    svg {
      height: 100px;
      width: auto;
      display: block;
      top: -20px;
      position: relative;
      float: right;
    }
  }

  .menu-wrapper {
    grid-column: 2 / 4;
    grid-row: 1 / 2;
    text-align: right;

    &.active {
      display: block;
      .menu-items {
        display: block;
        padding: 0;
        li {
          display: block;
          margin-right: 0;
          padding: 0;
          a {
            justify-content: center;
            border-bottom: 1px solid #ccc;
            height: 50px;
            font-weight: 300;
          }
        }
      }
    }

    .menu-items {
      display: inline-block;
      list-style-type: none;
      margin-top: 0;

      li {
        display: inline-block;
        margin-right: 20px;

        --hover-color: #000;
        &:nth-child(1) {
          --hover-color: #bb2126;
        }
        &:nth-child(2) {
          --hover-color: #17479d;
        }
        &:nth-child(3) {
          --hover-color: #ebe719;
        }
        &:nth-child(4) {
          --hover-color: #22b27c;
        }

        &:last-child {
          margin-right: 0;
        }

        a {
          display: flex;
          align-items: center;
          text-decoration: none;
          color: var(--color-grey);
          height: 65px;
          font-weight: 500;
          text-transform: uppercase;
          font-size: 14px;
          position: relative;
          transition: color 175ms linear;

          &:before {
            content: "";
            position: absolute;
            bottom: 15px;
            left: 50%;
            width: 0;
            height: 2px;
            background-color: var(--hover-color);
            -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
            transition: width 175ms cubic-bezier(0.4, 0.25, 0.3, 1);
          }
        }

        &.active {
          a {
            color: var(--hover-color);

            &:before {
              width: 100%;
            }
          }
        }

        &:hover {
          a {
            color: var(--hover-color);
            transition: color 175ms linear;
          }
          a:before {
            width: 100%;
          }
        }
      }
    }
  }
  .menu-button {
    display: none;
    background: #ffffff;
    border: 0;
    width: 100%;
    outline: none;

    i {
      position: relative;
      display: block;
      text-align: center;
      width: 50px;
      height: 5px;
      color: #fff;
      background: #000;
      transition: all 0.2s ease-out;
      margin: 0 10px 0 auto;

      &:after,
      &:before {
        content: "";
        width: 50px;
        height: 5px;
        background: #000;
        position: absolute;
        left: 0;
        transition: all 0.3s ease-in-out;
      }
      &:after {
        bottom: -16px;
      }
      &:before {
        top: -16px;
      }
    }

    &.active {
      i {
        background: #fff;
        &:after {
          bottom: 0;
          transform: rotate(-45deg);
          opacity: 1;
        }
        &:before {
          top: 0;
          transform: rotate(45deg);
          opacity: 1;
        }
      }
    }
  }
}

@media screen and (max-width: 1300px) {
  .header-container {
    .menu-items {
      li {
        margin-right: 15px !important;
        a {
          font-size: 12px !important;
        }
      }
    }
  }
}
@media screen and (max-width: 1024px) {
  .header {
    height: auto;

    .section-container {
      grid-column: full;
    }
  }

  .header-container {
    grid-template-areas:
      "list list list"
      "text text button";
    grid-template-rows: 1fr 65px;
    .logo-wrapper {
      display: block;
      padding-left: 15px;
    }
    .menu-wrapper {
      grid-area: list;
      display: none;
    }
    .fifty-wrapper {
      display: flex;
      grid-area: text !important;
    }
    .menu-button {
      grid-area: button !important;
      display: flex;
      justify-self: center;
      align-items: center;
    }
    .menu-items {
      li {
        margin-right: 0 !important;

        &:last-child {
          margin-bottom: 12px;
        }
        &:hover {
          a {
            background-color: var(--hover-color);
            color: #ffffff !important;

            &:before {
              display: none;
            }
          }
        }
      }
    }
  }
}
</style>
