<template>
  <div id="app">
    <Header />
    <transition name="fade" mode="out-in">
      <router-view />
    </transition>
    <Footer />
  </div>
</template>

<script>
import _ from "lodash";
import Header from "@/components/shared/header";
import Footer from "@/components/shared/footer";
export default {
  components: {
    Header,
    Footer
  },
  metaInfo: {
    title: "Rewind 2019 | lexpress.mu",
    titleTemplate: "%s - Rewind 2019 | lexpress.mu",
    htmlAttrs: {
      lang: "fr",
      amp: false
    }
  },
  mounted() {}
};
</script>

<style lang="scss">
#app {
  font-family: var(--font-poppins);
  background: #fff;
  overflow-x: hidden;
  position: relative;
  height: 100%;
  min-height: 100vh;
  padding-bottom: 50px;
}

*,
:before,
:after {
  margin: 0;
}

.fade-enter-active,
.fade-leave-active {
  transition: all 0.3s ease-in-out;
}
.fade-enter, .fade-leave-to /* .fade-leave-active in <2.1.8 */ {
  opacity: 0;
}

.main-wrapper {
  display: grid;
  grid-template-columns:
    [full-start] minmax(5vw, 1fr) [container-start] minmax(300px, 1300px)
    [container-end] minmax(5vw, 1fr) [full-end];
  grid-template-rows: auto;
}

.section-container {
  grid-column: container;
}

.full-section-container {
  grid-column: full;

  &.has-sub-containers {
    display: grid;
    grid-template-columns:
      [full-start] minmax(5vw, 1fr) [container-start] minmax(300px, 1300px)
      [container-end] minmax(5vw, 1fr) [full-end];
    grid-template-rows: auto;

    .full-section-container {
      grid-column: full;
    }

    .section-container {
      grid-column: container;
    }
  }
}

[data-layout="home"] {
  overflow-x: hidden;
}
</style>
