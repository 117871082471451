import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: () => import("../views/Home.vue")
  },
  {
    path: "/pov",
    name: "pov",
    component: () => import("../views/Pov.vue")
  },
  {
    path: "/retro-photos/:key?",
    name: "galleries",
    component: () => import("../views/Galleries.vue")
  },
  {
    path: "/portrait/:slug",
    name: "singlePortrait",
    component: () => import("../views/SinglePortrait.vue")
  },
  {
    path: "/parole-redac-chefs/:slug",
    name: "singleLeadership",
    component: () => import("../views/SingleLeadership.vue")
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior: (to, from, savedPosition) => {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve({ x: 0, y: 0 });
      }, 500);
    });
  }
});

export default router;
